export default (additionalScripts) => {
  if (additionalScripts) {
    additionalScripts.forEach((file) => {
      switch (file) {
        case 'addressBook':
          import(/* webpackChunkName: "js/addressBook" */'storefront/sfra/addressBook');
          break;
        case 'cart':
          import(/* webpackChunkName: "js/cart" */'storefront/sfra/cart');
          break;
        case 'checkout':
          import(/* webpackChunkName: "js/checkout" */'storefront/sfra/checkout');
          break;
        case 'checkoutRegistration':
          import(/* webpackChunkName: "js/checkoutRegistration" */'storefront/sfra/checkoutRegistration');
          break;
        case 'login':
          import(/* webpackChunkName: "js/login" */'storefront/sfra/login');
          break;
        case 'orderHistory':
          import(/* webpackChunkName: "js/orderHistory" */'storefront/sfra/orderHistory');
          break;
        case 'paymentInstruments':
          import(/* webpackChunkName: "js/paymentInstruments" */'storefront/sfra/paymentInstruments');
          break;
        case 'productDetail':
          import(/* webpackChunkName: "js/productDetail" */'storefront/sfra/productDetail');
          break;
        case 'productTile':
          import(/* webpackChunkName: "js/productTile" */'storefront/sfra/productTile');
          break;
        case 'profile':
          import(/* webpackChunkName: "js/profile" */'storefront/sfra/profile');
          break;
        case 'search':
          import(/* webpackChunkName: "js/search" */'storefront/sfra/search');
          break;
        case 'storeLocator':
          import(/* webpackChunkName: "js/storeLocator" */'storefront/sfra/storeLocator');
          break;
        default:
          if (process.env.NODE_ENV !== 'production') {
            console.error(`Unknown chunk "${file}".`); // eslint-disable-line no-console
          }
      }
    });
  }
}; // eslint-disable-line eol-last