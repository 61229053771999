import $ from 'jquery';

export default() => {
  $(document).on('change keyup', '.inputValidate', function () {
    // eslint-disable-next-line no-useless-escape
    const regex = /[^\w\s`!@#$£%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/g;
    $(this).val($(this).val().replace(regex, '')); // eslint-disable-line jquery/no-val
  });

  function isValidURL(url) {
    try {
      // eslint-disable-next-line no-new
      new URL(url); // URL constructor will throw an error if invalid
      return true;
    } catch {
      return false;
    }
  }

  $(document).on('change', '.validateName', function () {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // eslint-disable-next-line jquery/no-val
    if (emailRegex.test($(this).val()) || isValidURL($(this).val())) {
      // eslint-disable-next-line jquery/no-val
      $(this).val('');
    }
    const urlPattern = /(www|=|:|\.|https|https:\/\/|\/|#|\?)/g;
    $(this).val($(this).val().replace(urlPattern, '')); // eslint-disable-line jquery/no-val
  });
};

$(document).on('invalid', '#phoneNumber, #phone', function () {
  let newText = this.validationMessage;
  if (this.validity.tooShort && !this.validity.patternMismatch) {
    // eslint-disable-next-line jquery/no-data
    const oldText = $('#phoneNumber, #phone').data('range-error');
    // eslint-disable-next-line jquery/no-val
    const charLen = $(this).val().length;
    newText = oldText.replace('X', charLen);
    // eslint-disable-next-line jquery/no-attr
    $('#phoneNumber, #phone').attr('data-range-error', newText);
  }
  // eslint-disable-next-line jquery/no-text
  $('#phoneNumber, #phone').siblings('.invalid-feedback').text(newText);
});
